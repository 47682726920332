@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-Light.woff2);
	font-weight: 100 200;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-LightOblique.woff2);
	font-weight: 100 200;
	font-style: italic;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-Book.woff2);
	font-weight: 300;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-BookOblique.woff2);
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-Roman.woff2);
	font-weight: 400;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-Oblique.woff2);
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-Medium.woff2);
	font-weight: 500 600;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-MediumOblique.woff2);
	font-weight: 500 600;
	font-style: italic;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-Heavy.woff2);
	font-weight: 700 800;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-HeavyOblique.woff2);
	font-weight: 700 800;
	font-style: italic;
}

@font-face {
	font-family: "Avenir";
	src: url(fonts/Avenir/Avenir-Black.woff2);
	font-weight: 900;
}

html, body, #root {
	height: 100%;
	font-family: "Avenir", sans-serif !important;
	color: #000000;
	font-weight: 300;
	--tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.page-content h1, .page-content h1 span {
	text-transform: uppercase !important;
	font-size: 2.25rem !important;
    line-height: 2.5rem !important;
	margin-bottom: 1.5rem !important;
	font-weight: 500 !important;
}

@media (max-width: 767px) {
	.page-content h1, .page-content h1 span {
		font-size: 1.25rem !important;
		line-height: 1.75rem !important;
	}
}

.page-content {
	font-size: 1.125rem;
	line-height: 1.7rem;
}

.location-content img {
	display: inline-block;
}

.img-center img {
	display: inline-block !important;
}

.page-content h4, .page-content h4 span {
	text-transform: uppercase !important;
	font-weight: 700 !important;
	font-size: 1.125rem !important;
	line-height: 1.75rem !important;
	margin-bottom: 1.5rem !important;
}

.page-content p, .page-content p span {
	margin-bottom: 1.5rem !important;
}

.page-content b, .page-content b span {
	font-weight: 700 !important;
}

.page-content a, .page-content a span {
	--tw-text-opacity: 1 !important;
    color: #198FFF !important;
	text-decoration-line: underline !important;
	text-underline-offset: 1px !important;
	transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
	font-weight: 700;
}

.page-content a:hover, .page-content a span:hover {
	--tw-text-opacity: 1 !important;
    color: #1472cc !important;
	text-decoration-line: underline !important;
	text-underline-offset: 1px !important;
}

.img-full img {
	width: 100% !important;
}

ul {
	list-style: none; /* Remove default bullets */
}

ul li {
	margin-bottom: 1rem;
}
  
ul li::before {
	content: "\2022";
	color: #198FFF;
	font-weight: bold;
	display: inline-block;
	width: 1.5rem;
	font-size:2rem;
	vertical-align:middle;
	line-height:2rem;
}

.location-content ul li {
	margin-bottom: 0 !important;
}